import { createInjectionState } from '@vueuse/core'
import { tron, tronErrors } from '~/utils/tron-v2'

export const [useMultiSignProvider, useMultiSign] = createInjectionState(() => {
  const info = reactive({ isOwnerPermission: false, permissionId: 0 })
  const addressMuktiSign = ref('')

  const { account: accountCurrent } = tronComp.useAccountCurrent()
  const { account: accountMultiSign } = tronComp.useAccount(
    addressMuktiSign,
    toRef(accountCurrent, 'chainId'),
  )

  /**
   * 验证多签是否有权限
   */
  const onValidatePower = (isMultiSign: boolean | Ref<boolean>, id: number) => {
    if (!toValue(isMultiSign))
      return
    info.isOwnerPermission = false
    info.permissionId = 0
    const {
      owner_permission: ownerPermission,
      active_permission: activePermission,
    } = accountMultiSign
    console.log('ownerPermission', ownerPermission)
    console.log('activePermission', activePermission)
    if (!ownerPermission || !activePermission)
      info.isOwnerPermission = false

    if (ownerPermission.threshold > 1)
      info.isOwnerPermission = ownerPermission.keys.some((i: any) => (i.address === tron.tronWeb?.address.toHex(accountCurrent.address) && i.weight === ownerPermission.threshold))

    else if (ownerPermission.threshold === 1)
      info.isOwnerPermission = ownerPermission.keys.some((i: any) => (i.address === tron.tronWeb?.address.toHex(accountCurrent.address)))

    if (!info.isOwnerPermission) {
      activePermission?.forEach((item: any) => {
        item.keys.forEach((i: any) => {
          if (item.threshold > 1) {
            if (i.weight === item.threshold && i.address === tron.tronWeb?.address.toHex(accountCurrent.address) && tron.getPermission(item.operations, id))
              info.permissionId = item.id
          }

          if (item.threshold === 1) {
            if (i.address === tron.tronWeb?.address.toHex(accountCurrent.address) && tron.getPermission(item.operations, id))
              info.permissionId = item.id
          }
        })
      })

      if (!info.permissionId)
        throw tronErrors.MULTISIGN_ERROR()
    }
  }

  const setAddress = (address: string) => {
    if (!tron.isAddress(address))
      return
    addressMuktiSign.value = address
  }

  return {
    info,
    onValidatePower,
    setAddress,
  }
})
